.home {
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;

}

.home-video {
  position: fixed;
  text-align: center;
  width: 50%;
  left: 0;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
  float: left;
  transition: width 1s ease-in-out;

}

.hm-cta-section {
  position: static;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 999;
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #000;
  text-align: center;
}

.hm-cta-section * { vertical-align: middle; }

.hm-cta-description {
  margin-top: 0;
  margin-bottom: 17px;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
}

.price-block {
  display: inline-block;
  height: 48px;
  padding: 0px 5px;
  border-radius: 10px;
  background-color: #1d1d1d;
}

.hm-cta-btn {
  padding: 22px;
  font-size: 30px;
  margin-right: 20px;
  border-radius: 10px;
  background-color: #8D8862;
  transition: opacity .3s ease;
  font-family: museo-sans-display,sans-serif;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 900;
  color: #fff;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid #B3B3B3;

  border-radius: 8px;
  border: 1px solid rgba(219,219,219, 1);
  box-shadow: 0px 2px 4px rgba(0,0,0, 0.5);
  opacity: 1;
  background-color: rgba(137,27,27, 1);
}

.grey-big-btn{
  padding: 22px;
  font-size: 30px;
  margin-right: 20px;
  border-radius: 10px;
  border: 1px solid #5C5C5C;
  /* border-image: linear-gradient(180deg, #959595 , #5C5C5C); */
  /* border-image-slice: 1; */
  border-radius: 8px;
  border: 1px solid #959595 !important;
  opacity: 1;
  background-color: rgba(69,69,69, 1) !important;
}

.hm-cta-btn:hover, .hm-cta-btn:focus {
  background-color: rgb(64, 16, 16) !important;
}

.new-red-buy:focus, .new-red-buy:hover , .main-buy-btn:hover , .main-buy-btn:focus{
  background-color: rgb(64, 16, 16) !important;
}

.pre-order-btn:hover, .pre-order-btn:focus {
  background-color: #726e50;
}

.hm-cta-price-box {
  height: 71px;
  padding: 12px 19px;
  display: inline-block;
  border-radius: 10px;
  background-color: #1d1d1d;
}
.hm-cta-price {
  font-family: museo-sans-display,sans-serif;
  font-size: 30px;
  line-height: 48px;
  font-weight: 800;
}
.hm-cta-price span {font-size: 10px;}


.hm-details {
  width: 50%;
  left: 50%;
  padding-bottom: 100px;
  position: relative;
  float: left;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 48px;
  transition: width 1s ease-in-out;

}

.hm-details-genre {
  margin: 8px 0;
  padding-left: 0;
  list-style-type: none;
  display: flex;
  overflow-x: auto;
  align-items: flex-start;
  white-space: nowrap;
} 

.hm-details-cast::-webkit-scrollbar {height: 8px;}
.hm-details-genre::-webkit-scrollbar-track {background: transparent;}
.hm-details-genre::-webkit-scrollbar-thumb {background: transparent;}

.hm-details-genre li {
  display: inline-block;
  background: #1d1d1d;
  border-radius: 9px;
  line-height: 1;
  padding: 12px 15px;
  margin-top: 10px;
  max-width: 200px;
}

.new-selected{
  display: inline-block !important;
  background-color: #000000 !important;
  border-radius: 9px !important;
  line-height: 1 !important;
  padding: 12px 15px !important;
  margin-top: 10px !important;
  max-width: 200px !important;
  border: 1px solid #0DD9FF;
  cursor: pointer !important;
}

.new-selected h4{
  color: #0DD9FF !important;
}

.new-not-selected{
  display: inline-block !important;
  background-color: #000000 !important;
  border-radius: 9px !important;
  line-height: 1 !important;
  padding: 12px 15px !important;
  margin-top: 10px !important;
  max-width: 200px !important;
  border: 1px solid #FFFFFF;
  cursor: pointer !important;
}

.new-not-selected h4{
  columns: #FFFFFF !important;
}

.hm-details-genre > li > h4 {
  opacity: .6;
  font-weight: 700;
  margin: 0;
  line-height: 1;
  padding: 0;
  color: #fff;
  font-size: 16px;
}

.hm-details-genre li:not(:last-child) {
  margin-right: 8px;
}

.hm-details-desc {
  margin-top: 28px;
  font-weight: 500;
  max-width: 350px;
  font-size: 18px;
  line-height: 22px;
}

.hm-details-tags {
  margin-top: 10px;
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 10px;
}
.hm-details-tags li {
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
  margin-top: 10px;
}
.hm-details-tags > li > h4 {
  opacity: .6;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-size: 14px;
  font-weight: 700; 
}

.hm-details-section {margin-top: 33px;}

.hm-details-heading {
  margin-bottom: 15px;
  margin-top: 0;
  font-size: 18px;
  line-height: 18px;
  font-weight: 900;
}

.hm-play-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-size: 18px;
  background-image: linear-gradient(180deg, #3D3D3D 0%, #2D2D2D 100%);
  font-family: museo-sans-display,sans-serif;
  max-width: 365px;
  min-width: auto;
  width: auto;
  padding: 0;
  height: 69px;
  padding: 0 18px;
  margin-top: 32px;
}

.hm-play-btn:hover, .hm-play-btn:focus {
  background-image: linear-gradient(180deg, #333333 0%, #1f1f1f 100%);
}

/* .buy-with-card-button > .main-but-btn {

} */

.hm-play-btn img {
  height: 30px;
  margin-right: 24px;
  padding-bottom: 5px;
}
.hm-play-btn p {
  margin: 0;
  padding: 0;
  padding-bottom: 5px;
}


/* reviews section */
.hm-details-reviews {overflow-x: auto;display: flex;}
.hm-details-reviews >:not(:last-child) {margin-right: 15px;}
.hm-details-reviews > * {width: 450px;min-width: 450px;}
.hm-details-reviews > div {    
  padding: 50px 20px;
  border-radius: 8px;
  background-color: #1d1d1d;
}
.hm-details-reviews::-webkit-scrollbar {height: 8px;}
.hm-details-reviews::-webkit-scrollbar-track {background: transparent;}
.hm-details-reviews::-webkit-scrollbar-thumb {background: transparent;}
.hm-details-reviews > div > p {
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 10px;
  margin-top: 0;
}  
.hm-details-reviews > div > h3 {
  opacity: 0.6;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  text-align: center;
  font-style: italic;
}


/* press section */
.hm-details-press {overflow-x: auto;display: flex;}
.hm-details-press >:not(:last-child) {margin-right: 15px;}
.hm-details-press > * {min-width: fit-content;}
.hm-details-press::-webkit-scrollbar {height: 8px;}
.hm-details-press::-webkit-scrollbar-track {background: transparent;}
.hm-details-press::-webkit-scrollbar-thumb {background: transparent;}
.hm-details-press > a {
  overflow: hidden;
  border-radius: 8px;
  max-width: 100%;
  display: inline-block;
}
.hm-details-press > a > img {
  width: 243px;
  height: 123px;
  object-fit: cover;
  vertical-align: middle;
  display: inline-block;
  max-width: 100%;
}


/* trailer section */
.hm-details-trailers {
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
  align-items: flex-start;
}
.hm-details-trailers > * {min-width: fit-content;}
.hm-details-trailers::-webkit-scrollbar {height: 8px;}
.hm-details-trailers::-webkit-scrollbar-track {background: transparent;}
.hm-details-trailers::-webkit-scrollbar-thumb {background: transparent;}
.hm-details-trailer-block {padding-top: 0 !important;margin-top: 33px;}


/* cast section */
.hm-details-cast {
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
  align-items: flex-start;
}
.hm-details-cast > * {min-width: fit-content;}
.hm-details-cast::-webkit-scrollbar {height: 8px;}
.hm-details-cast::-webkit-scrollbar-track {background: transparent;}
.hm-details-cast::-webkit-scrollbar-thumb {background: transparent;}
.hm-details-cast > div {margin-right: 20px;text-align: center;}
.hm-details-cast > div > img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
}
.hm-details-cast > div > h3 {
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  white-space: nowrap;
}
.hm-details-cast > div > h2 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 900;
  white-space: nowrap;
}

.hm-mkey-section {
  max-width: 420px;
  margin-bottom: 4px;
  text-align: center;
}
.hm-mkey-item {
  padding: 10px 20px;
  border-radius: 8px;
  background-color: #1d1d1d;
  text-align: center;
  margin-bottom: 10px;
}
.hm-mkey-row {
  display: flex;
  grid-column-gap: 10px;
  margin-left: -10px;
  margin-right: -10px;
}
.hm-mkey-row:before, .hm-mkey-row:after {
  content: " ";
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}
.hm-mkey-row:after {clear: both;}
.hm-mkey-row > *:not(:first-child) {
  width: 58.33333333%;
  padding: 15px;
  border-radius: 8px;
  background-color: #1d1d1d;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  text-align: center;
  position: relative;
  float: left;
  min-height: 1px;
}
.hm-mkey-row > *:not(:last-child){
  padding: 15px;
  border-radius: 8px;
  background-color: #1d1d1d;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  text-align: center;
  width: 41.66666667%;
  position: relative;
  float: left;
  min-height: 1px;
}
.hm-mkey-row > div > p {
  width: 150px;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  font-weight: 500;
  color: #fff;
}
.hm-mkey-row > div > h3 {
  margin-top: 0px;
  font-size: 18px;
  line-height: 22px;
}
.hm-mkey-row > div > img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}
.hm-mkey-link {
  display: inline-block;
  margin-top: 50px;
  margin-bottom: 15px;
  transition: opacity 300ms ease;
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  text-decoration: underline;
  margin-left: 10px;
  margin-right: 10px;
}

.hm-mkey-title {
  margin-top: 20px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 900;
  text-align: center;
}
.hm-mkey-text {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  margin: 0.67em 0;
}

.hm-mkey-text .first {animation: fade1st 9s linear infinite;}

.hm-mkey-text .second {animation: fade1st 9s linear infinite;animation-delay: 3s;}

.hm-mkey-text .third {animation: fade1st 9s linear infinite;animation-delay: 6s;}

.shine {
	background: #222 -webkit-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff)) 0 0 no-repeat;
	-webkit-background-size: 150px;
	color: rgba(255, 255, 255, 0.3);
	-webkit-background-clip: text;
	-webkit-animation-name: shine;
	-webkit-animation-duration: 5s;
	-webkit-animation-iteration-count: infinite;
	text-shadow: 0 0px 0px rgba(255, 255, 255, 0.5);
}

@keyframes shine {
	0%, 10% {
		background-position: -1000px;
	}
	20% {
		background-position: top left;
	}
	90% {
		background-position: top right;
	}
	100% {
		background-position: 1000px;
	}
}

.modal-container {
  width: 100vw;
  height: 150px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 1px;
  left: 0;
  z-index: 10000;
  background: #1a1a1a;
  transition: all .4s;
}

.modal-body {
  background: #1a1a1a;
  width: 400px;
  min-height: 200px;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
}

.modal-header {
  display: flex;
  align-items: center;
  height: auto;
  justify-content: space-between;
}

.close-button {
  transition: all .4s;
  position: fixed;
  cursor: pointer;
  top: 15px;
  height: 20px;
  left: 20px
}

@media (max-width: 576px){
  .modal-body {width: 100%;height: 100%;}
}


@keyframes fade1st {
  0% {color:#fff} 
  5% {color:#39A3FF}
  20% {color:#39A3FF} 
  30% {color:#fff} 
  100% {color:#fff} 
}


/* mobile bottom cta */
.mobile-cta {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 999;
  padding-top: 25px;
  padding-bottom: 25px;
  text-align: center;
  display: none;

  background: rgb(0 0 0 / 85%);
  backdrop-filter: blur(5px);

}
.mobile-cta > * {vertical-align: middle;}
.mobile-heading {
  margin-bottom: 17px;
  margin-top: 0px;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
}

.fade-me{
  opacity: 0;
  animation: fade 1s ;
  animation-fill-mode: forwards;
}

@keyframes fade {
  0%   { 
    opacity: 0 ; 
  }
  100% { 
    opacity: 1;
  }
}

/* components */
.hm-fade-in {
  opacity: 0;
  transform: translateY(20px);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out,
    visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
}
.hm-fade-in.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}


/*Moviekey page*/
.trailer-block .w-embed-youtubevideo,
.related-sld .w-embed-youtubevideo {padding-top: 0 !important;margin-right: 20px;}
.w-embed-youtubevideo iframe {height: 100%;display: block;}

.new-btn{
  text-decoration: none;
  padding-top: 36px;
  min-width: 373px;
  font-family: museo-sans-display,sans-serif;
  font-weight: 900;
}

.buy-btn{
  padding: 28px 16px 26px !important;
  font-size: 30px !important;
  margin-right: 20px;
}
.price-block .price-usd span{font-size: 10px;}
.price-block {height: 71px;padding: 12px 19px;}
.price-block .price-usd{
  font-size: 30px;
  font-family: museo-sans-display, sans-serif;
  line-height: 48px;
  font-weight: 800;
}

.price-usd{
  font-size: 30px;
  font-family: museo-sans-display, sans-serif;
  line-height: 48px;
  font-weight: 800;
}

.the-most-mb{margin-bottom: 10px;}

@media screen and (max-width: 1038px) {
  body, html { overflow-x: hidden; }

  video {width: 100%;}
  .video {width: 100%;}

  .home-video {
    padding-right: 20px;
    padding-left: 20px;
    position: relative;
    width: 100%;
    left: auto;
    right: auto;
    z-index: 1;
  }

  .section-4 {max-width: 500px;}

  .hm-cta-section {display: none;}

  .hm-details {
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 44px;
    padding-bottom: 150px;
    left: 0;
    width: 100%;
    right: auto;
  }

  .hm-details-img {
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
  }

  .hm-details-reviews {max-width: none;}

  .mobile-cta {display: block;z-index: 9999999999;}

  .price-block {height: 52px;padding: 2px 9px;}

  .price-block .price-usd {font-size: 40px;}



  
  .mobile-center{
    text-align: center;
  }

  .hm-details-genre {
    margin: 8px 0;
    padding-left: 0;
    list-style-type: none;
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    /* justify-content: center; */
}

.hm-play-btn {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-size: 18px;
  background-image: linear-gradient(180deg, #3D3D3D 0%, #2D2D2D 100%);
  font-family: museo-sans-display,sans-serif;
  max-width: 365px;
  min-width: auto;
  width: auto;
  padding: 0;
  height: 69px;
  padding: 0 18px;
  margin-top: 32px;
}

.hm-details-desc {
  margin: auto;
  margin-top: 28px;
  font-weight: 500;
  max-width: 350px;
  font-size: 18px;
  line-height: 22px;
}

.hm-details-tags {
  margin-top: 10px;
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

}

@media screen and (min-width: 992px) {
  video {
    margin-top: 28px;
    height: 75vh;
    min-width: 700px;
  }
  .video {
    margin-top: 28px;
    height: 75vh;
    /* min-width: 700px; */
  }
}

@media screen and (min-width: 992px) and (max-width: 1599px) {
  .price-block {height: 48.50px;padding: 2px 9px;}
  .price-block .price-usd{font-size: 37px;}
}
@media screen and (max-width: 410px) {
  .price-block .price-usd{font-size: 25px;}
}

@media screen and (max-width: 455px) {
  /* .hm-details-genre > li > h4 {font-size: 12px;} */

  video {
    margin-top: 28px;
    min-height: 400px;
    min-width: 300px;
  }

  .video {
    margin-top: 28px;
    min-height: 400px;
    /* min-width: 300px; */
  }

  .hm-play-btn {
    max-width: 100%;
    width: 100%;
  }

  .hm-details-reviews > * {
    width: 300px;
    min-width: 300px;
    padding: 20px 20px !important;
  }
  .hm-details-reviews > div {    
    display: flex;
      flex-direction: column;
      justify-content: center;
  }




}


.azosans-black {
  font-family: azosans-black;
}
.azosans-light {
  font-family: azosans-light;
}
.azosans-bold {
  font-family: azosans-bold;
}
.decoration {
  text-decoration: underline;
}
.bg-animation {
  background: linear-gradient(88deg, #000000, #202020);
  background-size: 400% 400%;
  -webkit-animation: AnimationName 6s ease infinite;
  -moz-animation: AnimationName 6s ease infinite;
  animation: AnimationName 6s ease infinite;
}
          
@-webkit-keyframes AnimationName {
  0%{background-position:84% 0%}
  50%{background-position:17% 100%}
  100%{background-position:84% 0%}
}
@-moz-keyframes AnimationName {
  0%{background-position:84% 0%}
  50%{background-position:17% 100%}
  100%{background-position:84% 0%}
}
@keyframes AnimationName {
  0%{background-position:84% 0%}
  50%{background-position:17% 100%}
  100%{background-position:84% 0%}
}        
.custom-bg{
  background-image: radial-gradient(circle at 50% 0%, #1F2023 0%, #0A0A0A 100%);
  border: 1px solid #000000;
}
.custom-bg-text h3{
  background: linear-gradient(180deg, rgba(108,80,169,1) 0%, rgba(130,229,156,1) 54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(4px 4px 5px rgba(0, 0, 0, 0.4));
  -webkit-text-stroke-width: 0.1px;
  -webkit-text-stroke-color: #0062ff;
}
.table-section table,
.table-section table td,
.table-section table th{
  border: 1px solid #000000;
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
  .first-came{
      font-size: 38px;
      margin-bottom: 32px;
  }
  .consumer-text{
      font-size: 32px;
      margin-bottom: 35px;
  }
  .men-image{
      height: 1024px;
  }
  .reach-para{
      line-height: 50px;
  }
  .wrong-image{
      width: 30px;
  }
  .right-image{
      width: 50px;
  }
  .bg-image-shape{
      top: -15% !important;
      height: 870px;
      right: 0 !important;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1300px){
  .first-came-section{
      padding-left: 100px;
  }
  .bg-image-shape{
      top: -34%;
  }
}
@media screen and (max-width: 1299px){
  .bg-image-shape{
      right: -50px;
  }
}
@media screen and (max-width: 1199px){
  .bg-image-shape{
      display: none
  }
}
@media screen and (max-width: 767px){
  .persantage-saction{
     background-position: left;
  }
  .persantage-saction-thired{
      background-position: left;
  }
}
@media screen and (max-width: 399px){


  .hm-details-genre li{
    padding: 4px 8px;
  }

  .hm-details-genre li:not(:last-child) {
    margin-right: 5px;
  }

  .hero-section-content{
      margin-top: 30px;
  }
  .hero-section-content h2{
      font-size: 32px;
  }
  .hero-section-content h4{
      font-size: 20px;
      margin-bottom: 10px;
  }
  .hero-section-content p{
      margin-bottom: 10px;
  }
  .hero-section-content a{
      padding: 10px 0;
  }
}


.hm-mkey-section {
  max-width: 470px;
}


.terms{
  margin: 0;
  font-family: museo-sans, sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  background: rgb(11, 11, 11) !important;
  color: #ffffff !important;
  line-height: 20px !important;
  text-align: left !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  position: relative;
  z-index: 9999999999;
}

.terms-section h1 {
  color: #ffffff !important;

}

.terms-section p {
  color: #ffffff !important;
  font-size: 14.5px !important;

}


@media screen and (max-width: 410px) {
  .price-block .price-usd{font-size: 25px;}

  .smol-device{
    margin-top: -14px;
    margin-bottom: -18px;
  }

  .terms-body{
    padding: 94px 15px !important;
  }
}

@media screen and (max-width: 450px) {
  .terms-body{
    padding: 94px 20px !important;
  }

  .hm-details-desc{
    max-width: 100% !important;
  }
}

.CookieDeclaration{
  display: none;
}


.the-modal{
  /* style={{maxWidth:'500px',maxHeight:'300px',margin:'auto',marginTop:'10px'}} */
  max-width: 500px;
  max-height: 700px;
  margin: auto;
  margin-top: 120px;
  background-color: #111111;
  padding: 20px;
  padding-bottom: 60px;
  position: relative;
  z-index: 99999999;
}

.ReactModalPortal{
  z-index: 99999999;
  position: relative;
}

.the-x{
  margin-top: -5px;
  width:30px;
  height: 30px;
  text-align: right !important;
  display: inline-block;
  margin-bottom: 50px;
  cursor: pointer;
}

.fiill{
  display: block;
  margin: 10px auto;
  height: 30px;
  font-size: large;
}

.top-logo {
  border: 1px solid whitesmoke;
  display: inline-block;
  border-radius: 15px;
  text-align: center;
  margin-top: 10px;
  margin-right: 10px;
  padding: 8px;
  padding-top: 0px;
  margin-bottom: -10px;
  background-color: rgba(0, 0, 0, 0.7);
}

.log-outer{
  margin-bottom: -112px;
}

.log-outerB{
  margin-bottom: -62px;

}

.home-videoD , .home-videoC {
  z-index: 0 !important;
}

@media screen and (min-width: 450px) {
  .log-outer{
    margin-bottom: -116px;
  }
  .log-outerB{
    margin-bottom: -50px;

  }

  .home-videoD{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .main-align-a{
    margin-bottom: -90px;
  }
  
  .main-align-b{
    margin-top: 40px;
  }

}

.main-align-a{

}

.main-align-b{

}

@media screen and (max-width: 550px) {
  .home-videoD{
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-bottom: -118px;
  }
  .home-videoC{
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-bottom: -62px;
  }



}

.how-tb1{
  border-radius: 8px;
  opacity: 1;
  background-color: rgba(29,29,29, 1);
  width: 222px;
  /* height: 201px; */
  padding-top: 20px;
}


.how-tb2{
  margin: auto;
  text-align: center;
}

.how-tb3{
  height: 31px;
  border-radius: 0px 0px 9px 9px;
  opacity: 1;
  background-color: rgba(57,57,57, 1);
  text-align: center;
}

.how-tb3 p{
  padding-top: 6px;
}

a{
  color:white ;
  text-decoration: none;
}

.new-adjustment-button{

}





@media screen and (max-width: 550px) {

  .new-adjustment-button{
    line-height: 1 !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-right: 0px !important;
    font-size: 28px !important;
    width: 248.66px !important;
    height: 82.8px !important;
    padding-top: 27px !important;
  }


}

.width-adjuster{
  width: 50%;
  right: 0;
}

@media screen and (max-width: 1345px) and (min-width:1235px) {
  
  .home-video{
    width: 55%;
  }
  .hm-details{
    width: 45%;
    left: 55%;
  }
  .width-adjuster{
    width: 45%;
  }

}



@media screen and (max-width: 1235px) and (min-width:1038px) {
  
  .home-video{
    width: 60%;
  }
  .hm-details{
    width: 40%;
    left: 60%;
  }
  .width-adjuster{
    width: 40%;
  }

}

.museo-sans{
  font-family: 'museo-sans' , sans-serif;
}


/* how to page */

.tut-rect{
  border-radius: 8px;
  opacity: 1;
  background-color: rgba(42,42,42, 1);
  width: 320px;
  height: 70px;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: 8px;

  display: none !important;
}

.tut-rect p{
  font-size: 16px;
}

.tut-rect img {
  width: 35px;
  height: 35px;
  margin: 16px;
  margin-top: -8px;
}

.tut-box img{
  width: 35px;
  height: 35px;
  margin-top: 15px;
  margin-bottom: -4px;
}

.sixteen{
  font-size: 16px;
}

.cream-line{
  height: 87px;
  background: #D6D199;
  width: 3px;
}

.tut-box{
  border-radius: 8px;
  opacity: 1;
  background-color: rgba(42,42,42, 1);
  width: 106px !important;
  height: 100px;
  text-align: center;
  cursor: pointer;
}

.tut-box:hover {
  background-color: rgb(25, 25, 25);
}

.home-p{
  font-family: 'museo-sans' , sans-serif;
  text-align: left;
  box-shadow: 0px 2px 4px rgba(0,0,0, 0.5);
  opacity: 1;
  color: rgba(207,207,207,1);
  font-family: 'museo-sans' , sans-serif;
  font-size: 24px;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0px;
  margin: 8px 0px;
  margin-bottom:16px;
}

.new-red-buy , .main-buy-btn {
  border-radius: 8px;
  border: 1px solid rgba(219,219,219, 1);
  box-shadow: 0px 2px 4px rgba(0,0,0, 0.5);
  opacity: 1;
  background-color: rgba(137,27,27, 1);
}

.new-grey-btn{
  border-radius: 8px;
  border: 1px solid #959595 !important;
  opacity: 1;
  background-color: rgba(69,69,69, 1) !important;
}

.new-grey-btn:hover , .new-grey-btn:focus{
  background-color: rgb(50, 50, 50) !important;
}

.new-secure{
  border-radius: 8px;
  border: 1px solid rgba(151,151,151, 1);
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  max-width: 380px;
  margin: 10px 20px;
}

.cm-here{
  cursor: pointer;
  text-decoration: underline;
  display: inline-block;
  position: relative;
}

.cm-div{
  width: 274px;
  background-color: hsl(208, 81%, 35%);
  right: 0px;
  position: absolute;
  margin-bottom: 25px;
  font-size: 15px;
  padding: 5px 16px;
  border-radius: 8px;
  top: -58px;
  animation: fadeup 0.5s;
}

@keyframes fadeup {
  0%{
    opacity: 0;
    top: -48px;
}

  100%{
    opacity: 1;
  }
}


@media screen and (min-width: 450px) and (max-width: 1038px) {

  .hm-details-desc{
    margin: initial;
  }
  .new-secure{
    margin: 10px auto;
  }

}

.upper-banner{
  /* box-shadow: 0px 0px 26px rgba(0,0,0, 0.497602) inset; */
  /* opacity: 1; */
  /* background: linear-gradient(180deg, rgba(25, 25, 25, 1) 0%, rgba(21, 21, 21, 1) 100%); */
  background-size: 21.8723%;
  position: fixed;
  top: 0;
  animation: banner 0.8s ;
  background: #000000;
  box-shadow: 0 2px 0 0 #426582;
}

.delay1s{
  animation: delayy 1s;
}

@keyframes delayy {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

@keyframes banner {
  
  0%{
    opacity: 0;
    top: -120px;
  }

  100%{
    opacity: 1;
    top: 0;
  }

}


.noshowscroll {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.noshowscroll::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}