* {
  box-sizing: border-box;
  scroll-behavior: smooth;

}

/* @import 'node_modules/react-modal-video/scss/modal-video.scss'; */



body {
  margin: 0;
  font-family: museo-sans, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
  color: #FFFFFF;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  scroll-behavior: smooth;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Customize your main colors in :root variables */
:root {
  --main-background-color: #343a50;
  --card-background-color: #804980;
  --countdown-background-color: #433765;
  --main-text-color: #f7f6f4;
  --title-text-color: #3cba8b;
}

h2,
p {
  color: var(--main-text-color);
}

h3 {
  font-weight: normal;
}

h4 {
  font-size: 18px;
  line-height: 24px;
}

.w-row:before, .w-row:after {
  content: " ";
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}

.w-row:after {
  clear: both;
}

/* components */
.main-buy-btn {
  margin-right: 10px;
  border-radius: 10px;
  background-color: #8D8862;
  transition: opacity 300ms ease;
  font-family: museo-sans-display, sans-serif;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 30px;
  color: white;
  border: 1px solid #B3B3B3;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 8px;
}
.mint-btn{
  max-width: 282px;
  width: 100%
}


@media screen and (max-width: 991px) {
  /* components */
  .pre-order-btn {
      padding: 12px 32px 17px;
      font-size: 19px;
  }
}



@font-face {
  font-family:museo-sans;
  src: url(https://use.typekit.net/af/a28b50/00000000000000000000e803/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3) format("woff2");
  font-weight:500;
  font-style:normal;
  font-stretch:normal;
  font-display:auto;
}
  
@font-face {
  font-family:museo-sans;
  src: url(https://use.typekit.net/af/e3ca36/00000000000000000000e805/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3) format("woff2");
  font-weight:700;
  font-style:normal;
  font-stretch:normal;
  font-display:auto;
}
  
@font-face {
  font-family:museo-sans;
  src: url(https://use.typekit.net/af/9cf49e/00000000000000000000e807/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3) format("woff2");
  font-weight:900;
  font-style:normal;
  font-stretch:normal;
  font-display:auto;
}
  
@font-face {
  font-family:museo-sans-display;
  src:url(https://use.typekit.net/af/abdaa2/00000000000000007735995e/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3) format("woff2");
  font-weight:900;
  font-style:normal;
  font-stretch:normal;
  font-display:auto;
}

.azosans-black {
  font-family: azosans-black;
}
.azosans-light {
  font-family: azosans-light;
}
.azosans-bold {
  font-family: azosans-bold;
}
.decoration {
  text-decoration: underline;
}
.bg-animation {
  background: linear-gradient(88deg, #000000, #202020);
  background-size: 400% 400%;
  -webkit-animation: AnimationName 6s ease infinite;
  -moz-animation: AnimationName 6s ease infinite;
  animation: AnimationName 6s ease infinite;
}
          
@-webkit-keyframes AnimationName {
  0%{background-position:84% 0%}
  50%{background-position:17% 100%}
  100%{background-position:84% 0%}
}
@-moz-keyframes AnimationName {
  0%{background-position:84% 0%}
  50%{background-position:17% 100%}
  100%{background-position:84% 0%}
}
@keyframes AnimationName {
  0%{background-position:84% 0%}
  50%{background-position:17% 100%}
  100%{background-position:84% 0%}
}        
.custom-bg{
  background-image: radial-gradient(circle at 50% 0%, #1F2023 0%, #0A0A0A 100%);
  border: 1px solid #000000;
}
.custom-bg-text h3{
  background: linear-gradient(180deg, rgba(108,80,169,1) 0%, rgba(130,229,156,1) 54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(4px 4px 5px rgba(0, 0, 0, 0.4));
  -webkit-text-stroke-width: 0.1px;
  -webkit-text-stroke-color: #0062ff;
}
.table-section table,
.table-section table td,
.table-section table th{
  border: 1px solid #000000;
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
  .first-came{
      font-size: 38px;
      margin-bottom: 32px;
  }
  .consumer-text{
      font-size: 32px;
      margin-bottom: 35px;
  }
  .men-image{
      height: 1024px;
  }
  .reach-para{
      line-height: 50px;
  }
  .wrong-image{
      width: 30px;
  }
  .right-image{
      width: 50px;
  }
  .bg-image-shape{
      top: -15% !important;
      height: 870px;
      right: 0 !important;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1300px){
  .first-came-section{
      padding-left: 100px;
  }
  .bg-image-shape{
      top: -34%;
  }
}
@media screen and (max-width: 1299px){
  .bg-image-shape{
      right: -50px;
  }
}
@media screen and (max-width: 1199px){
  .bg-image-shape{
      display: none
  }
}
@media screen and (max-width: 767px){
  .persantage-saction{
     background-position: left;
  }
  .persantage-saction-thired{
      background-position: left;
  }
}
@media screen and (max-width: 399px){
  .hero-section-content{
      margin-top: 30px;
  }
  .hero-section-content h2{
      font-size: 32px;
  }
  .hero-section-content h4{
      font-size: 20px;
      margin-bottom: 10px;
  }
  .hero-section-content p{
      margin-bottom: 10px;
  }
  .hero-section-content a{
      padding: 10px 0;
  }
}

.bg-gray {
  --tw-bg-opacity: 1;
  background-color: rgb(17 17 17 / var(--tw-bg-opacity));
}

.p-2\.5 {
  padding: 0.625rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.max-w-\[40px\] {
  max-width: 40px;
}
.w-10 {
  width: 2.5rem;
}
.w-5 {
  width: 1.25rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.min-w-\[170px\] {
  min-width: 170px;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-center {
  text-align: center;
}
.p-2\.5 {
  padding: 0.625rem;
}
.bg-lightgray {
  --tw-bg-opacity: 1;
  background-color: rgb(24 24 24 / var(--tw-bg-opacity));
}

.azosans-bold {
  font-family: azosans-bold;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.p-2\.5 {
  padding: 0.625rem;
}
.bg-lightgray {
  --tw-bg-opacity: 1;
  background-color: rgb(24 24 24 / var(--tw-bg-opacity));
}

.py-\[106px\] {
  padding-top: 106px;
  padding-bottom: 106px;
}
.mr-\[25px\] {
  margin-right: 25px;
}
.ml-\[25px\] {
  margin-left: 25px;
}

.relative {
  position: relative;
}

#CookiebotWidget{
  display: none;
}

.follow-up{
  opacity: 1;
  color: rgba(248,248,248,1);
  /* font-family: "AzoSans-Italic"; */
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  letter-spacing: 0px;
  text-align: center;
}