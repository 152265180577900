.purchase-main {
  background: #000;
  min-height: 100vh;
  padding-bottom: 100px;
  position: relative;
}

.moviekeys-purchase-header-wrapper { display: flex; width: 100%; }

.moviekeys-purchase-header-inner {
  width: 50%;
  display: flex;
  padding: 13px 0;
  background-color: #1D1D1D;
  text-decoration: none;
  align-items: center;
}

.moviekeys-purchase-header-inner-img {padding: 0 18px;}

.moviekeys-purchase-header-inner-img > img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

.moviekeys-purchase-header-in-cart-main-img > img {
  max-width: 100%;
  height: 92px;
  vertical-align: middle;
  display: inline-block;
}

.moviekeys-purchase-header-inner-text h2 {
  margin: 0 0 6px;
  color: #FDFDFD;
  font-size: 24px;
  font-weight: 900;
  line-height: 1.2;
  font-family: museo-sans-display, sans-serif;
}

.moviekeys-purchase-header-inner-text p {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  font-family: museo-sans, sans-serif;
}

.in-cart-main {
  justify-content: space-between;
  padding: 0 0 0 40px;
  background-color: #131313;
}

.in-cart-main .moviekeys-purchase-header-in-cart-text {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  padding: 20px 0;
  font-family: museo-sans, sans-serif;
}

.in-cart-main .moviekeys-purchase-header-in-cart-text h4 {margin: 0 0 2px;font-weight: 500;}

.in-cart-main .moviekeys-purchase-header-in-cart-text p { margin: 0; }

.purchase-contains {
  font-family: museo-sans, sans-serif;
  font-weight: 500;
  text-align: center;
  color: #fff;
}

.purchase-contains h5 {
  padding: 90px 0;
  font-size: 24px;
  margin: 0;
  font-weight: 500;
}

.purchase-card-main {
  border: 1px solid #FFFFFF;
  border-radius: 8px;
  max-width: fit-content;
  width: 100%;
  margin: 0 auto;
  padding: 29px 24px 23px;
}

.purchase-card-main h4 {
  margin-top: 0;
  margin-bottom: 30px;
  font-weight: 500;
}

.purchase-card-logos {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

.purchase-card-logos img {margin-right: 10px;}

.purchase-card-logos p {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 300;
}

.purchase-card-text, .purchase-card-text-img{ display: flex; align-items: center; }

.button-2, button.button-2 {
  margin-right: 10px;
  padding: 12px 17px 16px;
  border-radius: 10px;
  background-color: #1160a4;
  box-shadow: inset 0 -6px 0 0 rgba(0, 0, 0, 0.44);
  -webkit-transition: opacity 300ms ease;
  transition: opacity 300ms ease;
  font-family: museo-sans-display, sans-serif;
  font-size: 20px;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 900;
  color: #fff;
}

button.button-2 span{ display: none; }

button.button-2 img{ display: none; }

.button-2:hover, button.button-2:hover { opacity: 0.9; }
  
.buy-with-card-button button.button-2 {
  min-width: 373px;
  font-size: 30px;
  padding: 22px 16px 26px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: #1160a4;
  box-shadow: inset 0 -6px 0 0 rgb(0 0 0 / 44%);
  transition: opacity .3s ease;
  font-family: museo-sans-display,sans-serif;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 900;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
}

.buy-with-card-button button.buy-btn:after { content: "CHECKOUT NOW"; }

.or-main {
  margin: 32px 0;
  display: block;
  font-size: 24px;
}

.connect-wallet-button .wallet-adapter-button {
  background-color: #11A458;
  margin-right: 10px;
  margin: 0 auto;
  border-radius: 50px; 
  box-shadow: inset 0 -6px 0 0 rgb(0 0 0 / 44%);
  transition: opacity 300ms ease;
  font-family: museo-sans-display, sans-serif;
  font-size: 30px;
  font-weight: 900;
  display: inline-block;
  text-transform: uppercase;
  height: 70px;
  max-width: 373px;
  padding-bottom: 5px;
  width: 100%;
}
.wallet-adapter-button:hover, .wallet-adapter-button:focus {
  background-color: #0f8347;
  box-shadow: inset 0 -6px 0 0 rgb(0 0 0 / 55%)
}

.connect-wallet-button .wallet-adapter-button .wallet-adapter-button-start-icon{ display: none; }

.wallet-currency { margin: 20px 0; }

.wallet-currency img { margin: 0 10px; }

.conditions-text {
  font-size: 14px;
  position: fixed;
  bottom: 43px;
  width: 100%;
  text-align: center;
}

.conditions-text a { font-size: 14px; color: #FFFFFF; }

.buy-with-card-button { display: flex; justify-content: center; gap: 18px;}

.buy-with-card-button .div-block-8 {
  display: flex;
  align-items: center;
  padding: 12px 19px !important;
  border-radius: 10px;
  background-color: #1d1d1d;
  height: 100%;
}

.buy-with-card-button .price-usd {
  line-height: 1.4;
  font-size: 30px;
  font-weight: 800;
  font-family: museo-sans-display,sans-serif;
}

.price-usd *{vertical-align: middle;}

.mint-solscan-link {
  color: var(--title-text-color);
  border-bottom: 1px solid var(--title-text-color);
  font-weight: bold;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  outline: none;
  text-decoration: none;
  text-size-adjust: 100%;
  margin: 14px auto 0;
  display: block;
  width: fit-content;
}

.mint-solscan-link:hover {border-bottom: 2px solid var(--title-text-color);}

.price-purchase {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}
.purchase-price {
  font-size: 42px;
  margin: 0;
  margin-right: 8px;
}
.purchase-label {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.purchase-label > .usd {
  font-size: 20px;
}
.purchase-label > .fees {
  font-size: 12px;
}

.mint-btn:hover, .mint-btn:focus {
  background-color: #104e85;
  box-shadow: inset 0 -6px 0 0 rgb(0 0 0 / 55%);
}

.gt-mint-btn:hover, .gt-mint-btn:focus {
  background-color: #104e85;
  box-shadow: inset 0 -6px 0 0 rgb(0 0 0 / 55%);
}
   
@media screen and (max-width: 767px) {
  .moviekeys-purchase-header-inner { width: 100%; }
  .in-cart-main { position: fixed; bottom: 0; }
  .purchase-contains h5 { padding: 16px 0; font-size: 18px; }
  .purchase-contains { padding: 0 9px; }
  .purchase-card-logos{ flex-direction: column; margin-bottom: 18px; }
  .purchase-card-text-img{ margin-bottom: 20px; }
  .buy-with-card-button {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .buy-with-card-button .div-block-8 { margin-bottom: 18px; }
  .or-main { margin-top: 20px; margin-bottom: 20px; }
  .in-cart-main { padding-left: 16px; }
  .in-cart-main .moviekeys-purchase-header-in-cart-text { font-size: 14px; }
  .conditions-text { position: unset; bottom: unset; }
  .buy-with-card-button button.main-buy-btn {
    max-width: 320px;
    min-width: unset;
    width: 100%;
    margin: 0 auto;
  }
  .purchase-main { padding-bottom: 150px; }
  .purchase-card-main { margin-bottom: 70px; }
  .buy-with-card-button .price-usd { font-size: 40px !important; }

  .buy-btn {
    min-width: 100% !important;
    font-size: 23px !important;  
  }
}

@media (min-width:768px) {
  .purchase-main{padding-bottom: 0;}
  .conditions-text{position: static;padding: 30px 0;}
}


/* mint page start */
.go-back-line-main {
  display: flex;
  justify-content: space-between;
  padding: 20px 32px 20px;
}
.go-back-line-main a {color: #fff;font-size: 24px;}
.mint-card-main {padding: 46px 60px 21px;text-align: left;}
.solana-btn {
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  color: inherit;
  font: inherit;
  margin: 0;
  background-color: #1D1D1D;
  padding: 14px 37px;
  font-size: 24px;
  border-radius: 10px;
  margin-bottom: 20px;
  border: 0;
  cursor: pointer;
}

.mint-card-main .buy-with-card-button {justify-content: flex-start;}
.mint-card-main-pera {
  max-width: 455px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-top: 20px;
  line-height: 1.4;
  font-size: 16px;
}
.purchase-card-main.mint-card-main {margin-top: 100px;}
.mint-card-main-pera.for-mobile {display: none;}

@media screen and (max-width: 767px) {
  .mint-page .conditions-text {display: none;}
  .purchase-card-main.mint-card-main {text-align: center;padding: 46px 24px 21px;}
  .go-back-line-main {padding: 20px 0px 20px;}
  .go-back-line-main a { font-size: 16px;}
  .mint-page .purchase-contains {padding-left: 15px;padding-right: 15px;}
  .purchase-card-main.mint-card-main {border: 1px solid #fff;}
  .purchase-card-main.mint-card-main {margin-top: 5px;}
  .mint-card-main-pera.for-mobile {
    display: block;
    margin-top: 10px;
    margin-bottom: 32px;
    font-size: 16px;
  } 
  .mint-card-main-pera  {display: none;} 
}
