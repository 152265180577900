.terms {
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    color: #000;
    background: #fff;
}

.terms-body {
    max-width: 56rem;
    margin: 0 auto;
    padding: 94px 0;
}

.terms-section {
    margin: 55px 0;
}

.terms h1 {
    color: #000;
    font-weight: 600;
}

.terms p {
    color: #000;
}

.terms-title {
    font-weight: 800 !important;
    margin: 24px 0;
}

.terms h1{
  line-height: 1;
}
